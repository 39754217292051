import React, { useEffect, useRef } from "react"
import { graphql } from 'gatsby';
import Seo from "../common/components/seo"
import { Link, Text } from "../common/components/atoms";
import lottie from "lottie-web";
import errorAnimation from "../modules/documents/404-error.json";
import Header from "../common/components/organism/Header"
import Footer from "../common/components/organism/Footer"
import { SiteConfig } from '../common/types'
import { useGlobalStateContext } from "../common/Context/app";

export interface NotFoundPageProp {
  data?: {
    siteConfig: {
      nodes: SiteConfig[]
    }
  }
} 

type Env = 'production' | 'development'
const env: Env = process.env.GATSBY_SANITY_STUDIO_API_DATASET as Env || 'production';

const NotFoundPage: React.FC<NotFoundPageProp> = ({ data }) => {

  const { setSiteConfig } = useGlobalStateContext();

  type lottieType = typeof lottie.loadAnimation
  const error = useRef<any>();
  const anim = useRef<lottieType | any>();

  const pageContext = {
    navigation: data?.siteConfig?.nodes?.find((item => item?.environment === env)),
  } as any  
  

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, []);


  useEffect(() => {    
    if (lottie && error) {
      anim.current = lottie.loadAnimation({
        container: error.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: errorAnimation
      });
    }
  }, [error, lottie]);

  
  
  
  return (
    <section className="relative min-h-[100vh] flex flex-grow justify-between flex-col" style={{ background: 'radial-gradient(#1165ff, #001056)' }}>
      <Header />
      <Seo title="404: Not found" />
      <div className="h-[350px] w-full" ref={error} />
      <div className="flex justify-center items-center flex-col text-white">
        {/* <h1 className="animate__animated animate__swing animate__slower animate__infinite text-7xl py-8" style={{ animationDirection: 'alternate-reverse' }}>404: Page Not Found</h1> */}
        <Text type="P">Unfortunately this page does not exist</Text>
        <Link to="/" className="p-3 my-8 block border-2 rounded">Go back to the Homepage</Link>
      </div>
      <Footer />
    </section>
  )
}

export default NotFoundPage


export const query = graphql`
{
  siteConfig: allSanitySiteConfiguration {
    nodes {
      address
      copyright
      email
      environment
      facebook
      instagram
      linkedin
      telephone
      title
      twitter
      youtube
      navigation {
        items {
          name
          link {
            slug {
              current
            }
            title
          }
          items {
            navanchor
            name
            link {
              title
              slug {
                current
              }
            }
          }
        }
        footerItems {
          name
          link {
            slug {
              current
            }
          }
          items {
            name
            navanchor
            link {
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
}
`;